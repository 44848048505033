import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import { Swipeable } from "react-swipeable"

import Container from "../components/Container"

export default function GamePost({ data, pageContext }) {
  const { markdownRemark: post } = data
  const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  const { next, prev } = pageContext

  const config = {
    onSwipedLeft: () => (window.location = next.frontmatter.path),
    onSwipedRight: () => (window.location = prev.frontmatter.path),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  }

  return (
    <Swipeable {...config}>
      <Layout>
        <Container>
          <div>
            {prev && (
              <Link id="prev" to={prev.frontmatter.path}>
                <i
                  title="Previous (Left arrow key)"
                  className="fas fa-arrow-left mfp-arrow-left mfp-arrow"
                ></i>
                <img
                  src={prev.frontmatter.img.publicURL}
                  className="mfp-img-left hide-from-lg"
                  alt="prevGameImg"
                />
              </Link>
            )}
          </div>
          <div>
            {next && (
              <Link id="next" to={next.frontmatter.path}>
                <i
                  title="Next (Right arrow key)"
                  className="fas fa-arrow-right mfp-arrow-right mfp-arrow"
                ></i>
                <img
                  src={next.frontmatter.img.publicURL}
                  className="mfp-img-right hide-from-lg"
                  alt="nextGameImg"
                />
              </Link>
            )}
          </div>
          <div
            id={post.frontmatter.id}
            className=" row bg-dark text-white games"
          >
            <a className="gamesite-close" href="/#section-games">
              <i className="fas fa-times"></i>
            </a>
            <div className="col-md-12">
              <Img
                key="gameImg"
                className="thumbnail margin-bottom-40"
                fluid={featuredImgFluid}
              />
            </div>
            {/* /.col */}
            <div className="col-md-8 margin-bottom-20">
              <h1 className="no-margin-top">{post.frontmatter.title}</h1>
              <ul>
                <li>
                  <strong>Kategorie:</strong> {post.frontmatter.kategorie}
                </li>
                <li>
                  <strong>Fokus:</strong> {post.frontmatter.fokus}
                </li>
              </ul>
              <p>{post.frontmatter.description}</p>
            </div>
            {/* /.col */}
            <div className="col-md-4 margin-bottom-20 padding-left-md-40">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <a
                className="popup-close btn btn-danger btn-rounded btn-lg margin-top-40"
                href="/#section-games"
              >
                Schliessen
              </a>
            </div>
          </div>
        </Container>
      </Layout>
    </Swipeable>
  )
}
export const query = graphql`
  query GamePath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        img {
          childImageSharp {
            fluid(maxWidth: 170, quality: 50) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
          publicURL
        }
        kategorie
        fokus
        description
        id
      }
    }
  }
`
